@media screen and (min-width: 580px) {
	.heroImage {
		background-image: url('../../../../assets/images/heroBg.jpg');
		padding-top: 196px;
		height: 100vh;
		background-repeat: no-repeat;
		width: auto;
		background-position: center;
		background-size: cover;
	}
	.heroImage::before {
		filter: blur(20px);
	}

	.heroContainer {
		display: -webkit-flex;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		height: -webkit-fill-available;
		margin-top: 0px;
	}
	.heroTitleText {
		font-family: 'articulat-cf';
		font-weight: 800;
		font-size: 90px;
		line-height: 126px;
		text-align: center;
		color: #fff;
		text-shadow: 1.5px 1.5px black;
		text-transform: capitalize;
	}

	.heroTextContainer {
		font-family: 'articulat-cf';
		color: #fff;
		text-shadow: 1.5px 1.5px black;
		font-style: normal;
		font-weight: 300;
		font-size: 60px;
		line-height: 81px;
		text-align: center;
		/* max-height: 160px; */
		/* height: 14vh; */
		display: -webkit-flex;
		display: flex;
		padding-right: 10%;
		padding-left: 10%;
		padding-bottom: 0%;
		/* margin-top: -6%; */
		align-items: flex-start;
		justify-content: space-around;
	}

	.heroLine {
		display: -webkit-flex;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		align-self: center;
		gap: 10px;
		width: 6px;
		/* max-height: 222px; */
		height: 140px;
	}

	.heroButtonAndLine {
		/* position: absolute; */
		width: -webkit-fill-available;
		text-align: -webkit-center;
		margin-bottom: 5%;
		padding-right: 5%;
		padding-left: 5%;
		/* top: 75vh; */
		display: -webkit-flex;
		display: flex;
		justify-content: center;
		flex-direction: column;
	}
	.heroButton {
		color: #fff;
		background-color: transparent;
		font-weight: 700;
	}
}
@media screen and (min-width: 1095px) {
	.heroContainer {
		display: -webkit-flex;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		height: -webkit-fill-available;
		margin-top: 60px;
	}
	.heroTextContainer {
		padding-bottom: 4%;
	}
}
@media screen and (min-width: 1420px) {
	.heroContainer {
		display: -webkit-flex;
		display: flex;
		justify-content: center;
		flex-direction: column;
		height: -webkit-fill-available;
		margin-top: 22vh;
		/* top: 40vh;
	position: absolute;
	left: 50vh; */
	}
	.heroButtonAndLine {
		width: -webkit-fill-available;
		text-align: -webkit-center;
		margin-bottom: -4vh;
		padding-right: 5%;
		padding-left: 5%;
		/* top: 75vh; */
		display: -webkit-flex;
		display: flex;
		justify-content: center;
	}
	.heroTitleText {
		font-family: 'articulat-cf';
		font-weight: 800;
		font-size: 70px;
		line-height: 120px;
		text-align: center;
		color: #fff;
		text-shadow: 1.5px 1.5px black;
		text-transform: capitalize;
	}
	.heroLine {
		height: 47vh;
	}
}

@media screen and (max-width: 579px) {
	.heroImage {
		background-image: url('../../../../assets/images/heroBg.jpg');
		height: 100vh;
		background-repeat: no-repeat;
		width: auto;
		background-position: center;
		background-size: cover;
	}
	.heroImage::before {
		filter: blur(20px);
	}

	.heroContainer {
		display: -webkit-flex;
		display: flex;
		justify-content: center;
		flex-direction: column;
		height: -webkit-fill-available;
	}
	.heroTitleText {
		font-family: 'articulat-cf';
		color: #fff;
		font-style: normal;
		font-weight: 300;
		font-size: 50px;
		line-height: 68px;
		text-align: center;
		text-transform: capitalize;
	}

	.heroTextContainer {
		font-family: 'articulat-cf';
		color: #fff;
		font-style: normal;
		font-weight: 300;
		font-size: 60px;
		line-height: 81px;
		margin-top: -107px;
		text-align: center;
		max-height: 160px;
		height: 14vh;
		margin-left: 5vh;
		margin-right: 5vh;
		display: -webkit-flex;
		display: flex;
		align-self: center;
		align-items: self-end;
		flex-direction: column;
	}

	.heroLine {
		display: -webkit-flex;
		display: flex;
		flex-direction: column;
		align-self: center;
		gap: 10px;
		width: 3px;
		height: 30%;
		color: #fff;
		margin-top: -2vh;
	}

	.heroButtonAndLine {
		position: absolute;
		width: -webkit-fill-available;
		height: -webkit-fill-available;
		text-align: -webkit-center;
		top: 80%;
	}
}
