@media screen and (min-width: 580px) {
	.formRow,
	.formFieldWrap {
		display: grid;
		grid-template-columns: 1fr auto;
	}
	.formRow.col-2 {
		grid-template-columns: repeat(2, 1fr);
		column-gap: 20px;
	}

	.formFieldLabel {
		font-weight: 500;
		margin-bottom: 5px;
		justify-self: center;
	}

	.selectFieldWrapper {
		grid-column: 1/-1;
		box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
			rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
			rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
			rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
		background-color: white;
		margin: 10px 0 5px;
		margin-top: 10px;
		border-radius: 0px;
		font-size: 14px;
		/* height: px; */
		

		transition: background-color 240ms, box-shadow 240ms;
	}

	[data-show-error='true'] {
		color: red;
	}
 

	 .ui.selection.dropdown.selectField {
		width: 100%;
		height: 31px;
        /* min-height: 0px;
        text-align: center;
		border: 1px solid #49a187;
		font-size: inherit;
		background-color: #0195ba33;
		font-weight: 400;
		font-family: inherit;
		border-radius: 0px; */
        color: black;
        text-align: center;
	} 

	.formFieldLabel::first-letter {
		text-transform: uppercase;
	}

	.errorMessage {
		color: tomato;
		text-align: right;
		font-size: 16px;
	}

	html,
	body {
		height: 100%;
	}
}
@media screen and (max-width: 579px) {
	.formRow,
	.formFieldWrap {
		display: grid;
		grid-template-columns: 1fr auto;
	}

	.formRow.col-2 {
		grid-template-columns: repeat(2, 1fr);
		column-gap: 20px;
	}

	.formFieldLabel {
		font-weight: 500;
		margin-bottom: 0px;
	}

	.selectFieldWrapper {
		grid-column: 1/-1;
		box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
			rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
			rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
			rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
		background-color: white;
		margin: 10px 0 5px;
		margin-top: 10px;
		border-radius: 0px;
		font-size: 14px;
		height: 31px;
		display: -webkit-flex;

		display: flex;

		transition: background-color 240ms, box-shadow 240ms;
	}

	[data-show-error='true'] {
		color: red;
	}

	.ui.selection.dropdown.selectField {
		width: 100%;
		height: 100%;
		border: 1px solid #49a187;
		padding: 5px 10px;
		font-size: 11px;
		background-color: #0195ba33;
		font-weight: 400;
		font-family: inherit;
		border-radius: 8px;
		text-align: center;
	}

	.formFieldLabel::first-letter {
		text-transform: uppercase;
	}

	.errorMessage {
		color: tomato;
		text-align: right;
		font-size: 16px;
	}
}
