@media screen and (min-width: 580px) {
	.cv {
		position: relative;
		height: fit-content;
		margin-bottom: -10vh;
	}
	.cvContainerRight {
		margin-right: 100px;
		text-align: right;
		height: fit-content;
		margin-top: -75px;
		margin-bottom: 5px;
	}
	.cvTitleContainerRight {
		position: relative;
		right: -22px;
		top: -103px;
	}
	.cvContentContainerRight {
		position: relative;
		height: fit-content;
		right: -51%;
		top: -127px;
		width: 50%;
	}

	.cvContainerLeft {
		margin-left: 100px;
		text-align: left;
		margin-top: -75px;
		margin-bottom: 5px;
		height: fit-content;
	}
	.cvTitleContainerLeft {
		position: relative;
		height: fit-content;
		left: -22px;
		top: -105px;
	}
	.cvContentContainerLeft {
		position: relative;
		height: fit-content;
		left: -22px;
		top: -128px;
		width: 50%;
	}

	.cvTitle {
		font-family: 'articulat-cf';
		font-style: normal;
		text-transform: uppercase;
		font-weight: 800;
		font-size: 60px;
		line-height: 84px;
		color: #f6a21c;
		margin-block-start: 0em;
		margin-block-end: 0em;
	}

	.cvContent {
		font-family: 'articulat-cf';
		font-style: normal;
		font-weight: 300;
		font-size: 18px;
		line-height: 32px;
		color: #000000;
		margin-block-start: 0em;
		margin-block-end: 0em;
	}

	.cvBackNumber {
		font-family: 'lemonmilkbold';
		font-style: normal;
		font-weight: 900;
		font-size: 200px;
		line-height: 240px;
		color: #17223c;
		opacity: 0.1;
		margin-block-start: 0em;
		margin-block-end: 0em;
	}

	.cvContainerLeft.reveal {
		position: relative;
		transform: translateX(-150px);
		margin-right: 150px;
		opacity: 0;
		transition: 1s all ease;
	}
	.cvContainerRight.reveal {
		position: relative;
		margin-right: 172px;
		transform: translateX(150px);
		opacity: 0;
		transition: 1s all ease;
	}

	.reveal.active {
		transform: translateX(0);
		opacity: 1;
	}
}
@media screen and (max-width: 579px) {
	.cv {
		position: relative;
		height: fit-content;
		margin-top: -4vh;
	}
	.cvContainerRight {
		text-align: right;
		height: -moz-fit-content;
		height: fit-content;
		display: -webkit-flex;

		display: flex;
		flex-direction: column;
		margin-left: 15%;
		margin-right: 15%;
	}
	.cvTitleContainerRight {
		position: relative;
		height: 82px;
		top: -5vh;
		padding-left: 7%;
	}
	.cvContentContainerRight {
		position: relative;
		height: fit-content;
		top: -7vh;
	}

	.cvContainerLeft {
		text-align: left;
		height: -moz-fit-content;
		height: fit-content;
		display: -webkit-flex;

		display: flex;
		flex-direction: column;
		margin-left: 15%;
		margin-right: 15%;
	}
	.cvTitleContainerLeft {
		position: relative;
		height: 82px;
		top: -5vh;
	}
	.cvContentContainerLeft {
		position: relative;
		height: -moz-fit-content;
		height: fit-content;
		top: -7vh;
	}

	.cvTitle {
		font-family: 'articulat-cf';
		font-style: normal;
		font-weight: 800;
		font-size: 28px;
		line-height: 34px;
		text-transform: uppercase;
		color: #f6a21c;
		margin-block-start: 0em;
		margin-block-end: 0em;
	}

	.cvContent {
		font-family: 'articulat-cf';
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 22px;
		color: #000000;
		margin-block-start: 0em;
		margin-block-end: 0em;
	}

	.cvBackNumber {
		font-family: 'lemonmilkbold';
		font-style: normal;
		font-weight: 700;
		font-size: 80px;
		/* line-height: 240px; */
		opacity: 0.1;
		margin-block-start: 0em;
		margin-block-end: 0em;
		color: #0195ba;
	}
	.cvContainerLeft.reveal {
		position: relative;
		/* transform: translateX(150px);
		opacity: 0;
		transition: 1s all ease; */
	}
	.cvContainerRight.reveal {
		position: relative;
		/* transform: translateX(-150px);
		opacity: 0;
		transition: 1s all ease; */
	}

	.reveal.active {
		/* transform: translateX(0);
		opacity: 1; */
		animation-name: example;
		animation-duration: 1.5s;
	}
	@keyframes example {
		0% {
			bottom: 100px;
		}
		
		100% {
			bottom: 0px;
		}
	}
}
