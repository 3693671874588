@media screen and (min-width: 580px) {
	html {
		box-sizing: border-box;
		font-size: 16px;

		--formWidth: 500px;
	}

	*,
	*:before,
	*:after {
		box-sizing: inherit;
	}

	body,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	ol,
	ul {
		margin: 0;
		padding: 0;
		font-family: 'articulat-cf';
		font-weight: normal;
	}

	ol,
	ul {
		list-style: none;
	}

	/* styles */

	.formComp {
		max-width: 80vw;
		width: 80vw;
	}
	.formAndButtonContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.formWrapper {
		display: inline-flex;
		justify-content: center;
		padding: 30px 20px;
		border-radius: 4px;
		background: #8d909695;
		margin-bottom: 30px;
		width: 60vw;
		border: 1px solid #f4a306;
	}
	.inputField {
		width: -webkit-fill-available;
		text-align: center;
	}

	.baseFormHeading {
		text-transform: capitalize;
	}

	.baseForm {
		display: grid;
		row-gap: 30px;
		min-width: -webkit-fill-available;
	}

	.formRow {
		display: grid;
		grid-template-columns: 1fr auto;
	}
	.formRow.col-2 {
		grid-template-columns: repeat(2, 1fr);
		column-gap: 20px;
	}

	button:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
	.openFormButton {
		display: -webkit-flex;
		display: flex;
		justify-content: center;
	}
	.ui.button,
	#openFormButton {
		display: block;
		border-radius: 8px;
		height: 75px;
		width: 300px;
		background: transparent;
		color: #000;
		font-family: 'articulat-cf';
		font-size: 20px;
		line-height: 24px;
		border: 6px solid #f4a306;
		font-weight: 800;
		/* box-shadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'; */
		border-color: #f4a306;
		color: #fff;
		box-shadow: 0 0 40px 40px #f4a306 inset, 0 0 0 0 #f4a306;
		-webkit-transition: all 150ms ease-in-out;
		transition: all 150ms ease-in-out;
	}
	button.ui.button:hover {
		background: #ffffff;
		border: 1px solid #f4a306;
		color: #000;
		box-shadow: 0 0 10px 0 #f4a306 inset, 0 0 10px 4px #f4a306;
	}
	button.ui.button:focus {
		background: #ffffff;
		color: #000;
		border: 1px solid #f4a306;
	}
	button.ui.button:active {
		border: 1px solid #f4a306;
	}
	button.ui.button:disabled {
		background-color: rgba(0, 0, 0, 0);
		color: #c1c1c1;
		border: 1px solid #f4a306;
		cursor: not-allowed;
		pointer-events: visible;
	}
	button.ui.button.submitButton {
		background-color: #49a187;
		box-shadow: none;
		color: white;
		cursor: pointer;
		font-family: 'articulat-cf';
		padding: 10px 8px;
		border-radius: 4px;
		border: 1px solid #49a187;
		font-size: 16px;
		text-transform: capitalize;
		grid-column: 1/-1;
		justify-self: center;
		width: -webkit-fill-available;
	}
	html,
	body {
		height: 100%;
	}
	.buttonLineTopLarge {
		width: 6px;
		/* max-height: 300px;  */
		position: relative;
		height: 360px;
		background: linear-gradient(
			180deg,
			rgba(244, 163, 6, 0) 16.15%,
			#f4a306 100%
		);
		bottom: 358px;
		left: 150px;
		margin-bottom: -248px;
	}
}
@media screen and (max-width: 579px) {
	.openFormButton {
		display: -webkit-flex;
		display: flex;
		justify-content: center;
		height: 40px;
	}
	.formAndButtonContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 10px;
	}

	button.ui.button {
		display: block;
		border-radius: 8px;
		height: 4vh;
		width: 25vh;
		gap: 10px;
		background-color: #fff;
		color: #fff;
		font-family: 'articulat-cf';
		font-size: 14px;
		line-height: 12px;
		border: 3px solid #f4a306;
		font-weight: 800;
		filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
		margin-bottom: 15px;
		box-shadow: 0 0 40px 40px #f4a306 inset, 0 0 0 0 #f4a306;
		-webkit-transition: all 150ms ease-in-out;
		transition: all 150ms ease-in-out;
	}
	button.ui.button:hover {
		background: #fff;
		border: '1px solid #0195BA';
		color: #000;
		box-shadow: 0 0 10px 0 #f4a306 inset, 0 0 10px 4px #f4a306;
	}
	button.ui.button:focus {
		background: #fff;
		color: #000;
		border: '1px solid #0195BA';
		box-shadow: 0 0 10px 0 #f4a306 inset, 0 0 10px 4px #f4a306;
	}
	button.ui.button:active {
		border: 1px solid #0195ba;
	}
	button.ui.button:disabled {
		background-color: 'rgba(0,0,0,0)';
		color: #c1c1c1;
		border: 1px solid #c1c1c1;
		cursor: 'not-allowed';
		pointer-events: 'visible';
	}
	.buttonLineTopLarge {
		width: 3px;
		max-height: 400px;
		position: relative;
		height: 22vh;
		background: linear-gradient(
			180deg,
			rgba(244, 163, 6, 0) 16.15%,
			#f4a306 100%
		);
		bottom: 21.5vh;
		left: 13.3vh;
	}

	html {
		box-sizing: border-box;
		font-size: 16px;

		--formWidth: 500px;
	}

	*,
	*:before,
	*:after {
		box-sizing: inherit;
	}

	body,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	ol,
	ul {
		margin: 0;
		padding: 0;
		font-family: 'articulat-cf';
		font-weight: normal;
	}

	ol,
	ul {
		list-style: none;
	}

	.formWrapper {
		display: inline-flex;
		justify-content: center;
		padding: 30px 20px;
		background: #17223c95;
		border-radius: 4px;
		margin-bottom: 30px;
		width: 90vw;
		border: 1px solid #f4a306;
	}
	.inputField {
		width: -webkit-fill-available;
		text-align: center;
	}

	.baseFormHeading {
		text-transform: capitalize;
	}

	.baseForm {
		display: grid;
		row-gap: 30px;
		min-width: -webkit-fill-available;
	}

	.formRow {
		display: grid;
		grid-template-columns: 1fr auto;
	}

	.formRow.col-2 {
		grid-template-columns: repeat(2, 1fr);
		column-gap: 20px;
	}
	button.ui.button.submitButton {
		background-color: #49a187;
		box-shadow: none;
		color: white;
		cursor: pointer;
		font-family: 'articulat-cf';
		padding: 10px 8px;
		border-radius: 4px;
		border: 1px solid #49a187;
		font-size: 16px;
		text-transform: capitalize;
		grid-column: 1/-1;
		justify-self: center;
		width: -webkit-fill-available;
	}

	button:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
}
