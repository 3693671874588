@media screen and (min-width: 580px) {
	.whoAreWeParagraph {
		/* background: linear-gradient(
			179.57deg,
			#e5e5e4 0.37%,
			rgba(229, 229, 228, 0) 85.95%
		); */
		font-family: 'articulat-cf';
		font-style: normal;
		font-weight: 300;
		font-size: 30px;
		line-height: 41px;
		text-align: center;
		min-height: 244px;
		padding-left: 226px;
		padding-right: 226px;
		padding-top: 24px;
		margin-bottom: -8%;
	}
	.whoAreWeLine {
		display: -webkit-flex;
		display: flex;
		flex-direction: column;
		align-self: center;
		gap: 10px;
		width: 6px;
		max-height: 222px;
		height: 222px;
		background: linear-gradient(
			180deg,
			rgba(244, 163, 6, 1) 0%,
			rgba(255, 255, 255, 1) 100%
		);
	}
	.whoAreWeContainer {
		display: -webkit-flex;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}
@media screen and (max-width: 579px) {
	.whoAreWeParagraph {
		font-family: 'articulat-cf';
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 19px;
		text-align: center;
		min-height: 244px;
		padding-left: 5vh;
		padding-right: 5vh;
		padding-top: 24px;
		/* margin-top: 15%; */
		margin-bottom: -33%;
	}
	.whoAreWeLine {
		display: -webkit-flex;
		display: flex;
		flex-direction: column;
		align-self: center;
		gap: 10px;
		width: 3px;
		max-height: 180px;
		height: 180px;
		background: linear-gradient(
			180deg,
			rgba(244, 163, 6, 1) 0%,
			rgba(255, 255, 255, 1) 100%
		);
	}
	.whoAreWeContainer {
		display: -webkit-flex;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}
