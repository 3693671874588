@media screen and (min-width: 580px) {
	.svgContainerHome {
		position: relative;
		width: 100%;
		height: 100%;
		top: 0;
	}
	svg {
		position: absolute;
		left: 2.3%;
		top: -50%;
	}
	.path {
		stroke-dasharray: 1;
		stroke-dashoffset: 1;
		animation: dash 5s forwards;
	}

	@keyframes dash {
		from {
			stroke-dashoffset: 1;
		}
		to {
			stroke-dashoffset: 0;
		}
	}
	.coreValueContainer {
		display: -webkit-flex;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.cvLine {
		display: -webkit-flex;
		display: flex;
		flex-direction: column;
		align-self: center;
		gap: 10px;
		width: 6px;
		/* max-height: 115px; */
		height: 526px;
		background: linear-gradient(
			180deg,
			rgba(244, 163, 6, 0) 16.15%,
			#f4a306 100%
		);
	}
}
@media screen and (max-width: 579px) {
	svg {
		display: none;
	}
	.coreValueContainer {
		display: -webkit-flex;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.cvLine {
		display: -webkit-flex;
		display: flex;
		flex-direction: column;
		align-self: center;
		gap: 10px;
		width: 3px;
		/* max-height: 115px; */
		height: 163px;
		background: linear-gradient(
			180deg,
			rgba(244, 163, 6, 0) 16.15%,
			#f4a306 100%
		);
	}
}
