.footerContainer {
	display: -webkit-flex;
	display: flex;
	justify-content: center;
	flex-direction: column;
	height: 185px;
	justify-content: space-evenly;
	background-color: #f4a306;
	align-items: center;
}
.footerImage {
	height: 55%;
}
.footerCopyright {
	font-family: 'articulat-cf';
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	text-transform: uppercase;
	color: #000000;
	margin-bottom: 15px;
}

.footerContactLink {
	font-family: 'articulat-cf';
	font-style: normal;
	line-height: 44px;
	text-decoration: none;
	position: relative;
	color: #000;
	padding: 0 4px;
	font-size: 24px;
	font-weight: 400;
}
.footerContactLink:hover {
	color: #fff;
}
