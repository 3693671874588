body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
@font-face {
	font-family: 'LemonmilkBold';
	src: local('LemonmilkBold'),
		url('../src/assets/fonts/LEMONMILK-Bold.otf') format('truetype');
	font-weight: bold;
}
@font-face {
	font-family: 'LemonmilkMedium';
	src: local('LemonmilkMedium'),
		url('../src/assets/fonts/LEMONMILK-Medium.otf') format('truetype');
	font-weight: 600;
}
